<!-- Finder de Gestión de Cobros / Pagos -->

<template>
  <div class="cobros_pagos_F" v-if="schema" style="justify-content:center">    
    
      
      <!-- Cabecera -->
      <div class="cabecera" v-if="Entorno.header.header">       
        <base_Header 
          :Entorno="Entorno.header"
          @onEvent="event_Header">          
        </base_Header>
      </div>

      <!-- Contenido -->
      <div class="contenedor conflex" style="background-color:white;justify-content:center"> 
        <div class="columna" style="width:50%;">

          <!-- Filtro de Busqueda -->                    
          <div style="display:flex; align-items:center">
            <v-select  
              style="flex: 0 0 25%"
              v-bind="$select"
              v-model="schema.ctrls.tip.value"
              :label="schema.ctrls.tip.label"
              :items="$store.state.datos_iniciales.pago_tipo"
              item-value="d"
              item-text="n"
              @change="buscarExec('ini')">
            </v-select>

            <v-select  
              style="flex: 0 0 20%"
              v-bind="$select"
              v-model="schema.ctrls.vto.value"
              :label="schema.ctrls.vto.label"
              :items="$store.state.datos_iniciales.pago_vto"
              item-value="d"
              item-text="n"
              @change="buscarExec('ini')">          
            </v-select>

            <compfecha 
              style="flex: 0 0 20%"
              :schema="schema.ctrls.fh" 
              :edicion=true>
            </compfecha>

            <v-text-field
              style="flex: 0 0 30%"
              v-bind="$input"
              v-model="schema.ctrls.txt.value"
              label="Búsqueda"
              @keypress.13="buscarExec('ini')">
            </v-text-field>

            <v-btn
              v-bind="$cfg.btra_cfg.standard"
              @click="buscarExec('ini')">
                <v-icon dark>{{ "mdi-filter" }}</v-icon>
            </v-btn>
          </div>
          
          <v-sheet v-bind="$cfg.styles.marco">
            <!-- Grid -->
            <base_Fgrid
              v-model="accionRow"
              :padre="stName" 
              :Entorno="Entorno"  
              height="615px"        
              :items-per-page=-1 
              show-select
              single-select          
              hide-default-footer
              fixed-header     
              dense
              @onEvent="event_Grid">

              <!-- Slot botonera Top -->
              <template v-slot:top_btns="{ }">         
                <div style="padding:10px 0 0 10px;">
                  <div style="display:flex">
                    <div style="display:flex;justify-content:space-between;width:200px">
                      <!-- View Información de la Cuenta -->

                      <info_cta_view
                        :padre="stName"
                        :tipo_cta="accionRow.length? accionRow[0].tipo_cta : 0"
                        :cta_id="accionRow.length? accionRow[0].cta_id : 0">                        
                      </info_cta_view>

                      <btraextra
                        :btra_permisos="schema.btra"
                        :btra_particular="Entorno.btra"
                        btra_modulo="standard"
                        @onEvent="event_btra_Top">              
                      </btraextra>   
                    </div>

                    <div style="padding-left:50%">
                      {{ `Registros: ${$store.state[stName].records.length}`}}

                      <v-btn
                        style="margin-left:10px"
                        v-bind="$cfg.btra_cfg.standard"            
                        title="Recargar Expedientes"                        
                        @click="refrescar">
                          <v-icon dark>{{ "mdi-refresh" }}</v-icon>
                      </v-btn>                      
                    </div>
                  </div>           
                </div>
              </template>             
            </base_Fgrid>

            <div class="conflex">
              <vllabel
                style="flex: 1 1 10%"
                label="Fras"
                :txt="schema.ctrls.fras.value">
              </vllabel>

              <vllabel
                style="flex: 1 1 20%"
                label="Ventas"
                :txt="schema.ctrls.totv.value | num(2)">
              </vllabel>

              <vllabel
                style="flex: 1 1 20%"
                label="Compras"
                :txt="schema.ctrls.totc.value | num(2)">
              </vllabel>

              <vllabel
                style="flex: 1 1 20%"
                label="Pendiente"
                :txt="schema.ctrls.pendiente.value | num(2)">
              </vllabel>
            </div>
          </v-sheet>
        </div>
        
        <div class="columna" style="margin-left:5px;width:49%;">
          <cobros_pagos_detalle 
            :padre="stName"
            :id="accionRow.length? accionRow[0].id : 0"
            :auxRecord="accionRow.length? accionRow[0] : {}" 
            :disparoFinder="disparo_cobros_pagos_detalle">
          </cobros_pagos_detalle>
        </div>
      </div>


      <!-- Componentes diámicos -->
      <v-dialog
        v-model="modal"
        content-class="modal"
        persistent>
          <div class="centrado">
            <component                 
                :is="componente_dinamico"
                :padre="stName"
                :accion="accion"
                :accionRow="accionRow.length? accionRow[0] : {}"
                :disparoFinder="disparo"         
                @reloadCuentas="ini_component"       
                @onEvent="$event.accion=='cerrar'? cerrar_dinamico() : ''">
            </component>           
          </div>
      </v-dialog>
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base"); 
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const btraextra = () => plugs.groute("btraextra_new.vue", "comp");
  const cobros_pagos_detalle = () => plugs.groute("cobros_pagos_detalle.vue", "comp");
  const compfecha = () => plugs.groute("compfecha.vue", "comp");
  const cobros_pagos_remesas = () => plugs.groute("cobros_pagos_remesas.vue", "comp");
  const cobros_pagos_facturas = () => plugs.groute("cobros_pagos_facturas.vue", "comp");
  const cobros_pagos_transferencias = () => plugs.groute("cobros_pagos_transferencias.vue", "comp");
  const info_cta_view = () => plugs.groute("info_cta_view.vue", "comp");
  
  export default {
    mixins: [mixinFinder],
    components: { 
      base_Header, 
      base_Fgrid, 
      btraextra, 
      compfecha, 
      cobros_pagos_detalle, 
      cobros_pagos_remesas, 
      cobros_pagos_facturas,
      cobros_pagos_transferencias,
      info_cta_view
    },

    props: {
      padre: { type:String, default: ''},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },
  

    data() {
      return {  
        schema:null,
        api:'cobros_pagos_F',
        stName:'stFcobros_pagos',
        accion:"",
        accionRow: [],   
        disparo_cobros_pagos_detalle:false,   
      
        headers: {},
        itemsEmp: [],
        itemsTipos: [],
        
        componente_dinamico:null,  
        modal:false,
        disparo:false,
      };
    },

    
    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");
        
        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;

        // configuro Entorno         
        this.Entorno.grid.autoload= true;
        this.Entorno.header.titulo = "Administración - Gestion Cobros / Pagos";
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo[this.Entorno.btra]));

        this.Entorno.btra.footer.extra = [
          { accion: "xls_ctas", btn: "btn_excel", title:'Listado Cuentas' },          
          { accion: "remesa", icono: "mdi-alpha-r-circle-outline", title:'Ver/Editar Remesa actual' },
        /*   { accion: "cobros_fras", icono: "mdi-bank-transfer-in", title:'Cobros Remesa x Nº Facturas' }, */
          { accion: "ordenes_de_pago", icono: "mdi-transfer", title:'Ordenes de Pago - Remesa Mapfre' },
          { accion: "xls_fras", btn: "btn_excel", title:'Listado Facturas Cuenta' },

        ];
        
        this.headers = {
          header: [
            { text: "Cuenta", value: "cta", witdh: "45%", slot:true, filtro:"fechahr", ffield:"fhhr" },
            { text: "Fras", value: "f", witdh: "5%", slot:true, style:"width:45%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" },
            { text: "Ventas", value: "v", witdh: "15%", slot:true, style:"width:45%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" },
            { text: "Compras", value: "c", witdh: "15%", slot:true, filtro:"fechahr", ffield:"fhhr" },
            { text: "Pediente", value: "p", witdh: "20%", slot:true, filtro:"fechahr", ffield:"fhhr" }
         ],

        };

        this.Entorno.grid.headers = this.set_headers();
      }, 


      //
      ini_schemaCtrls() {
        this.schema.ctrls.fh.value= this.actualDate();
      },


      // selecciono la primera linea del Grid y actualizo totales
      get_records_fin() {
        this.filterProv();
      },


      // calculo totales
      calcular_totales() {
        this.schema.ctrls.fras.value= 0, this.schema.ctrls.totc.value= 0, this.schema.ctrls.totv.value= 0, this.schema.ctrls.pendiente.value= 0;

        this.miSt.records.map(elem => {          
          this.schema.ctrls.fras.value= Number(this.schema.ctrls.fras.value) + Number(elem.f);
          this.schema.ctrls.totc.value= Number(this.schema.ctrls.totc.value) + Number(elem.c);
          this.schema.ctrls.totv.value= Number(this.schema.ctrls.totv.value) + Number(elem.v);
          this.schema.ctrls.pendiente.value= Number(this.schema.ctrls.pendiente.value) + Number(elem.p);         
        });
      },
      
  
      // ventana de remesa
      remesa() {
        this.componente_dinamico= "cobros_pagos_remesas";
        this.disparo= !this.disparo;
        this.modal=true;
      },


      // cobros de facturas
      /* cobros_fras() {
        this.componente_dinamico= "cobros_pagos_facturas";
        this.disparo= !this.disparo;
        this.modal=true;
      }, */


      // cobros por transferencia
      ordenes_de_pago() {
        this.componente_dinamico= "cobros_pagos_transferencias";
        this.disparo= !this.disparo;
        this.modal=true;
      },
      

      // muestro informe de los proveedores listados en pdf/excel
      async xls_ctas() {
        let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
          accion:'pagos',
          fnt:'listado',
          env:2,
          tip:this.schema.ctrls.tip.value,
          vto:this.schema.ctrls.vto.value,
          fh:this.schema.ctrls.fh.value,          
        }}; 
        
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });   
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank"); 
      },

      // listado excel de facturas de la cuenta / proveedor

      async xls_fras() {
        let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
          accion:'pagos',
          fnt:'listadoFacturas',
          env:2,
          tip:this.schema.ctrls.tip.value,
          vto:this.schema.ctrls.vto.value,
          fh:this.schema.ctrls.fh.value,
          cta: this.accionRow[0].cta,
          ema: this.accionRow[0].email,
          tipo_cta: this.accionRow[0].tipo_cta,
          cta_id: this.accionRow[0].cta_id,

        }};
        
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });   
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank"); 
      },


      // filtro listado de proveedores
      filterProv() {
        let txt= this.schema.ctrls.txt.value;        
        this.$store.commit(this.stName + '/data2State', { prop:'records', value:this.miSt.read_records.slice().filter(elem=> {
              
            return (
              elem.cta.toLowerCase().includes(txt.toLowerCase()) 
              /* elem.ref.toLowerCase().includes(txt.toLowerCase()) || 
              elem.cta_name.toLowerCase().includes(txt.toLowerCase()) ||           
              elem.fra_doc.toLowerCase().includes(txt.toLowerCase()) */
            )

        })});

        // calculo totales de compras, ventas, pendientes,...
        this.calcular_totales();

        this.disparo_cobros_pagos_detalle= !this.disparo_cobros_pagos_detalle;
        if (!this.miSt.records.length) { this.accionRow= []; return; }
        this.accionRow= [this.miSt.records[0]];        
      },


      // 
      cerrar_dinamico() {
        this.modal= false;
        this.componente_dinamico= null;
      },


      // recargo finder
      refrescar (){
        this.ini_component();
      },


      //
      info_cta() {

      }

    },


    watch: {
      accionRow() {
        this.disparo_cobros_pagos_detalle= !this.disparo_cobros_pagos_detalle;

        //console.log(this.accionRow);
        //boton de fras
        this.btnSet("xls_fras",{ disabled: !this.accionRow.length });

      }
    }
  }
</script>


